import React from 'react'
import './app.css'
import Navbar from './Components/Navbar/Navbar'
import Home from './Components/Home/Home'
import Flight from './Components/Flight/Flight'
import Hotel from './Components/Hotel/Hotel'
import Package from './Components/Package/Package'
import Main from './Components/Main/Main'
import Mainhotel from './Components/Hotel/Mainhotel'
import Mainpackage from './Components/Package/Mainpackage'
import Footer from './Components/Footer/Footer'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

const App = () => {
  return (
    <BrowserRouter>
    <Navbar/>
    <Routes>
    <Route path="/" element={<div><Home /><Main /></div>} />
    <Route path="/flight" element={<div><Flight /><Main /></div>} />
    <Route path="/hotel" element={<div><Hotel /><Mainhotel /></div>} />
    <Route path="/package" element={<div><Package /><Mainpackage /></div>} />
    </Routes>
    <Footer/> 
    </BrowserRouter>
  )
}

export default App
