import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './home.css';
import './sug.css';
import video from '../../Assets/home.mp4';
import { GrLocation } from 'react-icons/gr';
import { FiFacebook } from 'react-icons/fi';
import { AiOutlineInstagram } from 'react-icons/ai';
import { SiTripadvisor } from 'react-icons/si';
import { BsListTask } from 'react-icons/bs';
import { TbApps } from 'react-icons/tb';
import Aos from 'aos';
import 'aos/dist/aos.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import suggestionsList from './sug';
import {AiFillCloseCircle} from 'react-icons/ai'

const Flight = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [toFieldDisabled, setToFieldDisabled] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State for managing popup display

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0
      ? []
      : suggestionsList.filter((suggestion) => {
          if (inputLength <= 3) {
            // Filter by the first three letters
            return suggestion.toLowerCase().slice(0, inputLength) === inputValue;
          } else {
            // Filter by the full input string
            return suggestion.toLowerCase().includes(inputValue);
          }
        });
  };

  const onSuggestionSelected = (field, suggestion) => {
    if (field === 'from') {
      setFromValue(suggestion);
      setFromSuggestions([]);
      setToFieldDisabled(false);
      setToValue('');
      setEndDate(null);
    } else {
      setToValue(suggestion);
      setToSuggestions([]);
    }
  };

  const onInputChange = (field, value) => {
    if (field === 'from') {
      setFromValue(value);
      setFromSuggestions(getSuggestions(value));
    } else {
      setToValue(value);
      setToSuggestions(getSuggestions(value));
    }
  };

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const handleFlightSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      from: fromValue,
      to: toValue,
      startDate: startDate,
      endDate: endDate,
      name: e.target.elements.flightName.value,
      phone: e.target.elements.flightPhone.value
    };
    console.log("Flight form submitted:", formData);
    try {
      const response = await emailjs.send('service_9ofoktd', 'template_0zl2b9b', formData, 'PyRb6GjhzSTp12X4A');
      console.log('Form submitted successfully', response);
      setShowPopup(true); // Show popup after successful submission
      
      // Reset form fields
      setFromValue('');
      setToValue('');
      setStartDate(null);
      setEndDate(null);
      e.target.elements.flightName.value = '';
      e.target.elements.flightPhone.value = '';
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <section className='home'>
      <div className='overlay'></div>
      <video src={video} muted autoPlay loop type='video/mp4'></video>

      <div className='homeContent container'>
        <div className='textDiv'>
          <span data-aos='fade-up' className='smallText'>
            Our Packages
          </span>
          <h1 data-aos='fade-up' className='homeTitle'>
            Search Your Flight....
          </h1>
        </div>

        {/* Flight */}
        <form onSubmit={handleFlightSubmit}>          
          <div onClick={() => setShowPopup(false)} data-aos='fade-up' className='cardDiv grid'>
                    <div className='destinationInput'>
            <label className='city'>From:</label>
            <div className='input flex'>
              <div className="autosuggest-container">
                <input
                  type="text"
                  value={fromValue}
                  onChange={(e) => onInputChange('from', e.target.value)}
                  placeholder="Departure City" required 
                />
                {fromSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {fromSuggestions.map((suggestion, index) => (
                      <li key={index} onClick={() => onSuggestionSelected('from', suggestion)}>
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='destinationInput'>
            <label className='city'>To:</label>
            <div className='input flex'>
              <div className="autosuggest-container">
                <input
                  type="text"
                  value={toValue}
                  onChange={(e) => onInputChange('to', e.target.value)}
                  placeholder="Arrival City"
                  disabled={toFieldDisabled} required 
                />
                {toSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {toSuggestions.map((suggestion, index) => (
                      <li key={index} onClick={() => onSuggestionSelected('to', suggestion)}>
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='dateInput'>
            <label htmlFor='startDate'>Select your dates:</label>
            <div className='input flex'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat='MMM-dd-yyyy'
                placeholderText='Departure date'
                minDate={getToday()} // Set minimum date to today's date
                required 
              />&nbsp;&nbsp;&nbsp;
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat='MMM-dd-yyyy'
                placeholderText='Return date'
                minDate={startDate} // Set minimum date to start date
              />
            </div>
          </div>

          <div className='destinationInput'>
            <label className='city'>Name:</label>
            <div className='input flex'>
              <input id="flightName" type='text' placeholder='Enter Your Name ' required />
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='destinationInput'>
            <label className='city'>Phone:</label>
            <div className='input flex'>
              <input id="flightPhone" type='number' name='fphone' placeholder='Enter Phone number' required />
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='searchOptions flex'>
            <button className='btn'>Search</button>
          </div>

          </div>
          </form>

        {/* Popup Message */}
        {showPopup && (
          <div className="popup">
            <div onClick={() => setShowPopup(false)} className="closeNavbar">
              <AiFillCloseCircle className="icon"/>
            </div>            
            <div className="popup-inner">
              {/* Displaying the image */}
              <img src="https://wynva.com/Wynva_ads.jpg" alt="Flight" />

              {/* Buttons */}
              <div className="buttonGroup">
                <button className="btn" onClick={() => setShowPopup(false)}><a href="tel:+1(888)-529-5062">Continue</a></button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn" onClick={() => setShowPopup(false)}><a href="tel:+1(888)-529-5062">Call Now</a></button>
              </div>
            </div>
          </div>
        )}

        <div data-aos='fade-up' className='homeFooterIcons flex'>
          <div className='rightIcons'>
            <FiFacebook className='icon' />
            <AiOutlineInstagram className='icon' />
            <SiTripadvisor className='icon' />
          </div>

          <div className='leftIcons'>
            <BsListTask className='icon' />
            <TbApps className='icon' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Flight;
