import React, { useEffect } from 'react'
import './footer.css'
import video2 from '../../Assets/footer.mp4'
import {FiSend} from 'react-icons/fi'
import {MdOutlineTravelExplore} from 'react-icons/md'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillYoutube} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import {FaTripadvisor} from 'react-icons/fa'
import {FiChevronRight} from 'react-icons/fi'
import { PiAirplaneTakeoffLight } from "react-icons/pi";

import Aos from 'aos'
import 'aos/dist/aos.css'


const Footer = () => {

// scroll animation 
useEffect(()=>{
  Aos.init({duration: 2000})
}, [])


  return (
    <section className="footer">
      <div className="videoDiv">
      <video src={video2} muted autoPlay loop type="video/mp4"></video>
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-aos="fade-up" className="text">
            <small>KEEP IN TOUCH</small>
            <h2>Travel with us</h2>
          </div>

          <div className="inputDiv flex">
            <input data-aos="fade-up" type="text" placeholder='Enter Email Address'/>
            <button data-aos="fade-up" className="btn flex" type="submit">
              SEND<FiSend className='icon'/>
            </button>
          </div>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a href="#" className='logo flex'>
              <PiAirplaneTakeoffLight style={{ color: 'hsl(199, 100%, 33%)' }} />WYNVA.
              </a>
            </div>

            <div data-aos="fade-up" className="footerParagraph">
            Fly high and sleep tight with  <b> WYNVA!</b> Your ultimate travel companion for the best deals on flights and hotels. Book now and unlock your next adventure with ease!

            </div>

            <div data-aos="fade-up" className="footerSocials">
              <AiOutlineTwitter className='icon'/>
              <AiFillYoutube className='icon'/>
              <AiFillInstagram className='icon'/>
              <FaTripadvisor className='icon'/>
            </div>
          </div>

          <div className="footerLinks grid">
            {/* grp one */}
            <div data-aos="fade-up" data-aos-duration="3000" className="linkGroup">
              <span className="groupTitle">
                OUR AGENCY
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Services
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Insurance
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Agency
              </li>   
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Tourism
              </li>    
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Payment
              </li>                                                 
            </div>
            {/* grp Two */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
                PARTNERS
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Bookings
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Rentalcar
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                AirBNB
              </li>   
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Trivago
              </li>    
              <li className="footerList">
                <FiChevronRight className="icon"/>
                TripAdvisor
              </li>                                                 
            </div>
            {/* grp 3 */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
                LAST MINUTE
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                New York
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                California
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Washington DC
              </li>   
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Florida
              </li>    
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Hawaii
              </li>                                                 
            </div>



          </div>


          <div className="footerDiv flex">
            <small>ONE PLACE TO FULFILL YOUR TRAVEL NEEDS</small>
            <small>COPYRIGHT RESERVED - WYNVA 2024</small>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
